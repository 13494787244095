import * as React from 'react';

import {Button} from './button';

import './form.scss';

export interface ISignUpProps {}

export const SignUp: React.FC<ISignUpProps> = props => {
    return (
        <form method="post" className="fm">
            <input type="text" name="name" placeholder="Name" />
            <input type="email" name="email" placeholder="Email" />
            <Button
                button_text="Subscribe"
                button_url="#"
                module_class="btn_secondary"
            />
        </form>
    );
};
