import {findImage} from './get-image';

export const getStyles: any = (
    props: any,
    defaultStyles: any = {},
    overrides: any = {}
) => {
    const styles: any = defaultStyles || {};
    if (!styles['@media (min-width: 576px)']) {
        styles['@media (min-width: 576px)'] = {};
    }
    styles.backgroundColor = props.background_color || props.button_bg_color;
    styles.backgroundBlendMode = props.background_blend;
    styles.color =
        props.text_text_color ||
        props.button_text_color ||
        props.body_text_color;
    styles.fontSize = props.text_font_size || props.button_text_size;
    styles.borderColor = props.button_border_color;
    styles.borderRadius = props.button_border_radius;
    styles.letterSpacing = props.button_letter_spacing;

    if (props.module_alignment === 'center') {
        styles.textAlign = 'center';
    }

    const image =
        props.background_image &&
        findImage(
            'base',
            props.background_image.substring(
                props.background_image.lastIndexOf('/') + 1
            )
        );

    if (image && image.fluid.src && props.use_background_color_gradient) {
        styles.backgroundImage = `url(${image.fluid.src}), linear-gradient(180deg,${props.background_color_gradient_start} 0%,${props.background_color_gradient_end} 100%)`;
        styles.backgroundRepeat = 'no-repeat';
        styles.backgroundSize = 'cover';
        styles.backgroundPosition = 'center';
    } else if (props.background_image) {
        styles.backgroundImage = `url(${image.fluid.src})`;
        styles.backgroundRepeat = 'no-repeat';
        styles.backgroundSize = 'cover';
        styles.backgroundPosition = 'center';
    } else if (props.use_background_color_gradient) {
        styles.backgroundImage = `linear-gradient(180deg,${props.background_color_gradient_start} 0%,${props.background_color_gradient_end} 100%)`;
    }

    if (props.background_layout === 'dark') {
        styles.color = 'white';
    }

    if (
        props.box_shadow_style === 'preset1' ||
        props.box_shadow_style === 'preset2'
    ) {
        styles.boxShadow = `${props.box_shadow_horizontal || '0px'} ${
            props.box_shadow_vertical
        } ${props.box_shadow_blur || '0px'} 0px ${props.box_shadow_color}`;
    } else if (props.box_shadow_style) {
        styles.boxShadow = `inset ${props.box_shadow_horizontal || '0px'} ${
            props.box_shadow_vertical
        } ${props.box_shadow_blur || '0px'} 0px ${props.box_shadow_color}`;
    } else if (props.box_shadow_style) {
        //console.log(props.box_shadow_style);
    }

    if (props.custom_padding) {
        styles.padding = props.custom_padding
            .split('|')
            .map((a: string) => (a.length ? a : '0'))
            .join(' ');
    }

    if (props.custom_margin && !defaultStyles.margin) {
        styles['@media (min-width: 576px)'].margin = props.custom_margin
            .split('|')
            .map((a: string) => (a.length ? a : '0'))
            .join(' ');
    }

    styles.h1 = {
        fontSize: props.header_font_size,
        lineHeight: props.header_line_height
    };

    for (let i = 2; i <= 6; i++) {
        styles[`h${i}`] = {
            fontSize: props[`header_${i}_font_size`],
            lineHeight: props[`header_${i}_line_height`],
            color: props[`header_${i}_text_color`]
        };
    }

    for (let override in overrides) {
        styles[override] = overrides[override];
    }

    return styles;
};
