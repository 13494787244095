import * as React from 'react';
import {getStyles} from '../helpers';
import styled from 'styled-components';
import {Image} from './image';

interface IconProps {
    icon: string;
    color: string;
}

const Icon: React.FC<IconProps> = ({icon, color}) => {
    const iconCode = _icons[icon.replace(/%/g, '')];

    return (
        <i
            className="et-icon"
            style={{color}}
            dangerouslySetInnerHTML={{__html: iconCode}}
        />
    );
};

const InnerBlurb: any = (props: any) => {
    const B = styled.div(
        getStyles(
            props,
            {},
            {
                display: props.icon_placement === 'left' ? 'flex' : 'block',
                h4: {
                    color: props.header_text_color
                }
            }
        )
    );

    if (!props.icon_placement || props.icon_placement === 'top') {
        return (
            <B className={props.module_class}>
                <Image src={props.image} />
                <div
                    style={{
                        display: 'block',
                        textAlign: props.text_orientation || 'left'
                    }}
                >
                    <h4>{props.title}</h4>
                    <div
                        dangerouslySetInnerHTML={{__html: props.children}}
                    ></div>
                </div>
            </B>
        );
    }

    if (props.icon_placement === 'left') {
        return (
            <B className={props.module_class}>
                <div
                    style={{
                        maxWidth: props.image_max_width,
                        width: props.use_icon ? 'auto' : '100%',
                        marginRight: '10px'
                    }}
                >
                    {props.use_icon ? (
                        <Icon icon={props.font_icon} color={props.icon_color} />
                    ) : (
                        <Image src={props.image} />
                    )}
                </div>
                <div className="">
                    <h4>{props.title}</h4>
                    <div
                        dangerouslySetInnerHTML={{__html: props.children}}
                    ></div>
                </div>
            </B>
        );
    }

    return (
        <B>
            <h4>{props.title}</h4>
            <div dangerouslySetInnerHTML={{__html: props.children}}></div>
        </B>
    );
};

export const Blurb: React.FC<any> = props => {
    return !props.url || props.url === '#' ? (
        <InnerBlurb {...props} />
    ) : (
        <a href={props.url}>
            <InnerBlurb {...props} />
        </a>
    );
};

export default Blurb;

const _icons = [
    '&#x21;',
    '&#x22;',
    '&#x23;',
    '&#x24;',
    '&#x25;',
    '&#x26;',
    '&#x27;',
    '&#x28;',
    '&#x29;',
    '&#x2a;',
    '&#x2b;',
    '&#x2c;',
    '&#x2d;',
    '&#x2e;',
    '&#x2f;',
    '&#x30;',
    '&#x31;',
    '&#x32;',
    '&#x33;',
    '&#x34;',
    '&#x35;',
    '&#x36;',
    '&#x37;',
    '&#x38;',
    '&#x39;',
    '&#x3a;',
    '&#x3b;',
    '&#x3c;',
    '&#x3d;',
    '&#x3e;',
    '&#x3f;',
    '&#x40;',
    '&#x41;',
    '&#x42;',
    '&#x43;',
    '&#x44;',
    '&#x45;',
    '&#x46;',
    '&#x47;',
    '&#x48;',
    '&#x49;',
    '&#x4a;',
    '&#x4b;',
    '&#x4c;',
    '&#x4d;',
    '&#x4e;',
    '&#x4f;',
    '&#x50;',
    '&#x51;',
    '&#x52;',
    '&#x53;',
    '&#x54;',
    '&#x55;',
    '&#x56;',
    '&#x57;',
    '&#x58;',
    '&#x59;',
    '&#x5a;',
    '&#x5b;',
    '&#x5c;',
    '&#x5d;',
    '&#x5e;',
    '&#x5f;',
    '&#x60;',
    '&#x61;',
    '&#x62;',
    '&#x63;',
    '&#x64;',
    '&#x65;',
    '&#x66;',
    '&#x67;',
    '&#x68;',
    '&#x69;',
    '&#x6a;',
    '&#x6b;',
    '&#x6c;',
    '&#x6d;',
    '&#x6e;',
    '&#x6f;',
    '&#x70;',
    '&#x71;',
    '&#x72;',
    '&#x73;',
    '&#x74;',
    '&#x75;',
    '&#x76;',
    '&#x77;',
    '&#x78;',
    '&#x79;',
    '&#x7a;',
    '&#x7b;',
    '&#x7c;',
    '&#x7d;',
    '&#x7e;',
    '&#xe000;',
    '&#xe001;',
    '&#xe002;',
    '&#xe003;',
    '&#xe004;',
    '&#xe005;',
    '&#xe006;',
    '&#xe007;',
    '&#xe009;',
    '&#xe00a;',
    '&#xe00b;',
    '&#xe00c;',
    '&#xe00d;',
    '&#xe00e;',
    '&#xe00f;',
    '&#xe010;',
    '&#xe011;',
    '&#xe012;',
    '&#xe013;',
    '&#xe014;',
    '&#xe015;',
    '&#xe016;',
    '&#xe017;',
    '&#xe018;',
    '&#xe019;',
    '&#xe01a;',
    '&#xe01b;',
    '&#xe01c;',
    '&#xe01d;',
    '&#xe01e;',
    '&#xe01f;',
    '&#xe020;',
    '&#xe021;',
    '&#xe022;',
    '&#xe023;',
    '&#xe024;',
    '&#xe025;',
    '&#xe026;',
    '&#xe027;',
    '&#xe028;',
    '&#xe029;',
    '&#xe02a;',
    '&#xe02b;',
    '&#xe02c;',
    '&#xe02d;',
    '&#xe02e;',
    '&#xe02f;',
    '&#xe030;',
    '&#xe103;',
    '&#xe0ee;',
    '&#xe0ef;',
    '&#xe0e8;',
    '&#xe0ea;',
    '&#xe101;',
    '&#xe107;',
    '&#xe108;',
    '&#xe102;',
    '&#xe106;',
    '&#xe0eb;',
    '&#xe010;',
    '&#xe105;',
    '&#xe0ed;',
    '&#xe100;',
    '&#xe104;',
    '&#xe0e9;',
    '&#xe109;',
    '&#xe0ec;',
    '&#xe0fe;',
    '&#xe0f6;',
    '&#xe0fb;',
    '&#xe0e2;',
    '&#xe0e3;',
    '&#xe0f5;',
    '&#xe0e1;',
    '&#xe0ff;',
    '&#xe031;',
    '&#xe032;',
    '&#xe033;',
    '&#xe034;',
    '&#xe035;',
    '&#xe036;',
    '&#xe037;',
    '&#xe038;',
    '&#xe039;',
    '&#xe03a;',
    '&#xe03b;',
    '&#xe03c;',
    '&#xe03d;',
    '&#xe03e;',
    '&#xe03f;',
    '&#xe040;',
    '&#xe041;',
    '&#xe042;',
    '&#xe043;',
    '&#xe044;',
    '&#xe045;',
    '&#xe046;',
    '&#xe047;',
    '&#xe048;',
    '&#xe049;',
    '&#xe04a;',
    '&#xe04b;',
    '&#xe04c;',
    '&#xe04d;',
    '&#xe04e;',
    '&#xe04f;',
    '&#xe050;',
    '&#xe051;',
    '&#xe052;',
    '&#xe053;',
    '&#xe054;',
    '&#xe055;',
    '&#xe056;',
    '&#xe057;',
    '&#xe058;',
    '&#xe059;',
    '&#xe05a;',
    '&#xe05b;',
    '&#xe05c;',
    '&#xe05d;',
    '&#xe05e;',
    '&#xe05f;',
    '&#xe060;',
    '&#xe061;',
    '&#xe062;',
    '&#xe063;',
    '&#xe064;',
    '&#xe065;',
    '&#xe066;',
    '&#xe067;',
    '&#xe068;',
    '&#xe069;',
    '&#xe06a;',
    '&#xe06b;',
    '&#xe06c;',
    '&#xe06d;',
    '&#xe06e;',
    '&#xe06f;',
    '&#xe070;',
    '&#xe071;',
    '&#xe072;',
    '&#xe073;',
    '&#xe074;',
    '&#xe075;',
    '&#xe076;',
    '&#xe077;',
    '&#xe078;',
    '&#xe079;',
    '&#xe07a;',
    '&#xe07b;',
    '&#xe07c;',
    '&#xe07d;',
    '&#xe07e;',
    '&#xe07f;',
    '&#xe080;',
    '&#xe081;',
    '&#xe082;',
    '&#xe083;',
    '&#xe084;',
    '&#xe085;',
    '&#xe086;',
    '&#xe087;',
    '&#xe088;',
    '&#xe089;',
    '&#xe08a;',
    '&#xe08b;',
    '&#xe08c;',
    '&#xe08d;',
    '&#xe08e;',
    '&#xe08f;',
    '&#xe090;',
    '&#xe091;',
    '&#xe092;',
    '&#xe0f8;',
    '&#xe0fa;',
    '&#xe0e7;',
    '&#xe0fd;',
    '&#xe0e4;',
    '&#xe0e5;',
    '&#xe0f7;',
    '&#xe0e0;',
    '&#xe0fc;',
    '&#xe0f9;',
    '&#xe0dd;',
    '&#xe0f1;',
    '&#xe0dc;',
    '&#xe0f3;',
    '&#xe0d8;',
    '&#xe0db;',
    '&#xe0f0;',
    '&#xe0df;',
    '&#xe0f2;',
    '&#xe0f4;',
    '&#xe0d9;',
    '&#xe0da;',
    '&#xe0de;',
    '&#xe0e6;',
    '&#xe093;',
    '&#xe094;',
    '&#xe095;',
    '&#xe096;',
    '&#xe097;',
    '&#xe098;',
    '&#xe099;',
    '&#xe09a;',
    '&#xe09b;',
    '&#xe09c;',
    '&#xe09d;',
    '&#xe09e;',
    '&#xe09f;',
    '&#xe0a0;',
    '&#xe0a1;',
    '&#xe0a2;',
    '&#xe0a3;',
    '&#xe0a4;',
    '&#xe0a5;',
    '&#xe0a6;',
    '&#xe0a7;',
    '&#xe0a8;',
    '&#xe0a9;',
    '&#xe0aa;',
    '&#xe0ab;',
    '&#xe0ac;',
    '&#xe0ad;',
    '&#xe0ae;',
    '&#xe0af;',
    '&#xe0b0;',
    '&#xe0b1;',
    '&#xe0b2;',
    '&#xe0b3;',
    '&#xe0b4;',
    '&#xe0b5;',
    '&#xe0b6;',
    '&#xe0b7;',
    '&#xe0b8;',
    '&#xe0b9;',
    '&#xe0ba;',
    '&#xe0bb;',
    '&#xe0bc;',
    '&#xe0bd;',
    '&#xe0be;',
    '&#xe0bf;',
    '&#xe0c0;',
    '&#xe0c1;',
    '&#xe0c2;',
    '&#xe0c3;',
    '&#xe0c4;',
    '&#xe0c5;',
    '&#xe0c6;',
    '&#xe0c7;',
    '&#xe0c8;',
    '&#xe0c9;',
    '&#xe0ca;',
    '&#xe0cb;',
    '&#xe0cc;',
    '&#xe0cd;',
    '&#xe0ce;',
    '&#xe0cf;',
    '&#xe0d0;',
    '&#xe0d1;',
    '&#xe0d2;',
    '&#xe0d3;',
    '&#xe0d4;',
    '&#xe0d5;',
    '&#xe0d6;',
    '&#xe0d7;',
    '&#xe600;',
    '&#xe601;',
    '&#xe602;',
    '&#xe603;',
    '&#xe604;',
    '&#xe605;',
    '&#xe606;',
    '&#xe607;',
    '&#xe608;',
    '&#xe609;',
    '&#xe60a;',
    '&#xe60b;',
    '&#xe60c;',
    '&#xe60d;',
    '&#xe60e;',
    '&#xe60f;',
    '&#xe610;',
    '&#xe611;',
    '&#xe612;',
    '&#xe008;'
];
