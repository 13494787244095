import * as React from 'react';
import styled from 'styled-components';
import {getStyles} from '../helpers';

export interface ITextProps {
    header_font_size?: string;
    background_layout?: string;
}

export const Text: React.FC<ITextProps> = props => {
    const Wrapper = styled.div(getStyles(props));
    const style = props.background_layout || 'light';

    return (
        <Wrapper
            className={`mod mod-text mod-${style}`}
            dangerouslySetInnerHTML={{__html: props.children}}
        ></Wrapper>
    );
};

export default Text;
