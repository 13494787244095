import * as React from 'react';
import {renderContent, getStyles} from '../helpers';
import styled from 'styled-components';
import {Row} from './row';

interface SectionProps extends React.HTMLAttributes<HTMLElement> {
    specialty?: 'string';

    disabled?: 'on';
}

export const Section: React.FC<SectionProps> = props => {
    if (props.disabled === 'on') {
        return null;
    }

    const Wrapper = styled.div(getStyles(props));

    if (props.specialty) {
        return (
            <Wrapper id={props.module_id ? props.module_id : ''}>
                <Row>{renderContent(props.children)}</Row>
            </Wrapper>
        );
    }

    return (
        <Wrapper id={props.module_id ? props.module_id : ''}>
            {renderContent(props.children)}
        </Wrapper>
    );
};

export default Section;
