import * as React from 'react';
import * as Blocks from './blocks';

export interface ConfigItem {
    /**
     * The component that the shortcode will render
     */
    component: React.ComponentType<any>;
    /**
     * Defautl props to pass the to the component
     */
    props?: {[key: string]: string};
}

export const config: {[key: string]: ConfigItem} = {
    et_pb_blurb: {component: Blocks.Blurb},
    et_pb_button: {component: Blocks.Button},
    et_pb_column: {component: Blocks.Column},
    et_pb_column_inner: {component: Blocks.Column, props: {gutter: '0'}},
    et_pb_image: {component: Blocks.Image},
    et_pb_row: {component: Blocks.Row},
    et_pb_row_inner: {component: Blocks.RowInner},
    et_pb_section: {component: Blocks.Section},
    et_pb_text: {component: Blocks.Text},
    et_pb_signup: {component: Blocks.SignUp},
    et_pb_code: {component: Blocks.Code},
    et_pb_divider: {component: Blocks.Divider}
};

export * from './helpers';
export * from './blocks';
