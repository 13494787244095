import * as React from 'react';

import {renderContent, getStyles} from '../helpers';
import styled from 'styled-components';

export const RowInner: React.FC<any> = props => {
    const Wrapper = styled.div(
        getStyles(props, {
            display: 'flex',
            flexWrap: 'wrap',
            position: 'relative',
            margin: '0 auto'
        })
    );

    return (
        <Wrapper className="mod mod-row-inner">
            {renderContent(props.children)}
        </Wrapper>
    );
};

export default RowInner;
