import * as React from 'react';

import {renderContent, getStyles} from '../helpers';
import styled from 'styled-components';

import './row.scss';

export const Row: React.FC<any> = props => {
    if (props.disabled === 'on') {
        return '';
    }

    const Wrapper = styled.div(
        getStyles(props, {
            display: 'flex',
            flexWrap: 'wrap',
            position: 'relative',
            width: '80%',
            maxWidth: '1080px',
            margin: '0 auto',
            padding: '27px 0'
        })
    );

    return (
        <Wrapper className="mod mod-row">
            {renderContent(props.children)}
        </Wrapper>
    );
};

export default Row;
