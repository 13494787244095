import * as React from 'react';

import {config} from '../index';

type RenderContentType = (content: React.ReactNode | string) => React.ReactNode;

export const renderContent: RenderContentType = content => {
    if (typeof content !== 'string') {
        return content;
    }

    const out = [];

    const str = content.split('\n').join('');
    const propsRegex = /(\w+)=&#8221;(.*?)&#82/gm;
    const regex = new RegExp(
        '\\[(\\[?)(' +
            Object.keys(config).join('|') +
            ')(?![\\w-])([^\\]\\/]*(?:\\/(?!\\])[^\\]\\/]*)*?)(?:(\\/)\\]|\\](?:([^\\[]*(?:\\[(?!\\/\\2\\])[^\\[]*)*)\\[\\/\\2\\])?)(\\]?)',
        'gm'
    );

    let m;
    while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        const code = config[m[2]] || {component: ''};

        const props: {[key: string]: string} = {rawProps: m[3]};
        let propsMatch;
        while ((propsMatch = propsRegex.exec(m[3])) !== null) {
            if (propsMatch.index === propsRegex.lastIndex) {
                propsRegex.lastIndex++;
            }

            props[propsMatch[1]] = propsMatch[2];
        }

        const Component = code.component;
        if (code.component) {
            out.push(
                <Component
                    {...(code.props || {})}
                    {...props}
                    key={out.length}
                    children={m[5]}
                />
            );
        }
    }

    const match = /\[(\S+)/.exec(content);
    if (match && match[1] && !config[match[1]]) {
        console.warn(`Posible unknown block "${match[1]}" has been found.`);
    }

    return out;
};
