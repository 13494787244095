import * as React from 'react';
import {findImage} from '../helpers';
import Img from 'gatsby-image';

export interface DiviImageProps {
    align: string;
    force_fullwidth: 'no' | 'off';
    src: string;
    children?: React.ReactNode;
}

var images: any = null;

export const Image: React.FC<DiviImageProps> = props => {
    if (!props.src) {
        return null;
    }

    const style = props.style || ({} as any);

    const image = findImage(
        'base',
        props.src
            .substring(props.src.lastIndexOf('/') + 1)
            .replace('&#215;', 'x')
    );

    if (props.max_width) {
        style.maxWidth = props.max_width;
    }

    return !image ? null : (
        <Img style={style} className="img" fluid={image.fluid} />
    );
};

export default Image;
