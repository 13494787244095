import * as React from 'react';
import {getStyles} from '../helpers';
import styled from 'styled-components';

export interface IButtonProps {
    /**
     * The text of the button
     */
    button_text: string;
    /**
     * The url of the button
     */
    button_url: string;
    /**
     * The css class to add to the button
     */
    module_class?: string;

    button_alignment?: string;
}

export const Button: React.FC<IButtonProps> = props => {
    const A = styled.a(
        getStyles(props, {
            border: '2px solid'
        })
    );

    return (
        <div className="btn_wrapper">
            <A
                className={'mod mod-btn ' + (props.module_class || '')}
                href={props.button_url}
            >
                {props.button_text}
            </A>
        </div>
    );
};

export default Button;
