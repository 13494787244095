import * as React from 'react';
import {renderContent, getStyles} from '../helpers';
import styled from 'styled-components';

const getColumnWidth = (props: any): string => {
    const [one, two] = props.type.split('_');

    return `${100 * (one / two)}%`;
};

export const Column: React.FC = props => {
    const Column = styled.div(
        getStyles(props, {
            position: 'relative',
            'min-height': '1px',
            width: '100%',
            //paddingLeft: props.gutter,
            //paddingRight: props.gutter,
            //marginRight: '5.5%',
            '@media (min-width: 576px)': {
                'flex-basis': 0,
                'flex-grow': '1',
                maxWidth: getColumnWidth(props)
            }
        })
    );

    return (
        <Column className="mod mod-col">{renderContent(props.children)}</Column>
    );
};

Column.defaultProps = {gutter: '15px'};

export default Column;
