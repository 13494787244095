import * as React from 'react';

export interface ICodeProps {}

export const Code: React.FC<ICodeProps> = props => {
    if (!props.children) {
        return null;
    }

    const html = props.children
        .toString()
        .replace(/<!--(.*?)-->/g, '')
        .replace(/&#8221;/g, '"')
        .replace(/&#8243;/g, '"')
        .replace(/&#038;/g, '&');

    return <div dangerouslySetInnerHTML={{__html: html}}></div>;
};

export default Code;
