import * as React from 'react';
import {getStyles} from '../helpers';
import styled from 'styled-components';

export interface IDividerProps {
    module_class?: string;
}

export const Divider: React.FC<IDividerProps> = props => {
    const Wrapper = styled.div(getStyles(props));

    return <Wrapper className={props.module_class} />;
};

export default Divider;
