import * as React from 'react';

export interface IFooterProps {}

export const Footer: React.FC<IFooterProps> = () => {
    return (
        <div className="footer">
            <div className="footer-inner">
                <a href="/">Welcome</a>
                <a href="https://github.com/error-tracker" target="_blank">
                    Error Tracker Github
                </a>
            </div>
        </div>
    );
};

export default Footer;
