import {useStaticQuery, graphql} from 'gatsby';

let images: any = [];

/**
 * Gets all of the images for a graphql query
 */
export const getImages = (): any => {
    if (images.length) {
        return images;
    }

    const data = useStaticQuery(graphql`
        query allTheImagesQuery {
            allImageSharp {
                nodes {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                    parent {
                        ... on File {
                            id
                            name
                            publicURL
                            relativeDirectory
                            url
                            extension
                            base
                        }
                    }
                }
            }
        }
    `);

    if (!data) {
        return images;
    }

    images = data.allImageSharp.nodes;

    return images;
};

/**
 * Finds an image baised on a key value
 */
export const findImage = (key: string, value: string): any => {
    return getImages().find((item: any) => {
        return item.parent[key] === value;
    });
};

export default findImage;
