/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import {findImage} from '../wordpress';
import Img from 'gatsby-image';
import Helment from 'react-helmet';
import {WpAssets} from './wp-assets';
import Footer from './footer';

import './layout.scss';
import './wp-styles.scss';

export interface IGTMProps {
    /**
     * The google tag manager code U-XXXX
     */
    code: string;
}

export const GTM: React.FC<IGTMProps> = ({code}) => {
    if (process.env.NODE_ENV !== 'production') {
        return null;
    }

    const gtmCode = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${code}');`;

    return (
        <Helment>
            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{__html: gtmCode}}
            ></script>
            <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${code}`}
            ></script>
        </Helment>
    );
};

const Layout: React.FC = ({children}) => {
    return (
        <>
            <WpAssets />
            <GTM code={process.env.GATSBY_GA_CODE || ''} />
            <nav
                className="navbar justify-content-between navbar-expand navbar-light bg-light"
                style={{width: '80%', maxWidth: '1080px', margin: '0 auto'}}
            >
                <a className="navbar-brand" href="/" style={{width: '200px'}}>
                    <Img fluid={findImage('base', 'et-logo-rect.png').fluid} />
                </a>
                <div
                    id="navbar"
                    style={{justifyContent: 'flex-end'}}
                    className="collapse navbar-collapse"
                >
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                target="_blank"
                                href="https://github.com/error-tracker"
                                rel="noopener noreferrer"
                            >
                                Github
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
            <main>{children}</main>
            <Footer />
        </>
    );
};

export default Layout;
