import * as React from 'react';
import Helmet from 'react-helmet';

if (process.env.NODE_ENV === 'production') {
    require('../wp-assets/common.css');
    require('../wp-assets/gatsby.css');
}

export const WpAssets: React.FC = () => {
    const styles = [
        'https://fonts.googleapis.com/css?family=Montserrat%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2Cregular%2Citalic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&amp;ver=5.2.2#038;subset=latin,latin-ext'
    ];

    if (process.env.NODE_ENV !== 'production') {
        styles.push(
            'http://etwp.stage.zportal.co.uk/wp-content/themes/divi-3.18.7/0-styles/styles-common.css'
        );
        styles.push(
            'http://etwp.stage.zportal.co.uk/wp-content/themes/divi-3.18.7/0-styles/styles-gatsby.css'
        );
    }

    return (
        <Helmet>
            {styles.map((item, index) => (
                <link
                    key={index}
                    rel="stylesheet"
                    href={item}
                    type="text/css"
                    media="all"
                />
            ))}
        </Helmet>
    );
};
